<template>
  <div>
    <header class="px-4 py-2 bg-indigo-900 text-white">
      <nav class="shadow-sm">
        <div class="mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between">
            <div class="flex">
              <div class="flex-shrink-0 flex items-center space-x-4">
                <router-link
                  tag="button"
                  :to="{ name: 'user-patients' }"
                  exact-active-class="font-medium border-b border-white"
                  href="#"
                  class="inline-flex items-center px-1 pt-1 border-b border-transparent text-sm leading-5 focus:outline-none focus:border-white transition duration-150 ease-in-out"
                  >{{ $t("user.menu.patient.search") }}</router-link
                >
                <router-link
                  tag="button"
                  :to="{ name: 'user-patients-create' }"
                  exact-active-class="font-medium border-b border-white"
                  href="#"
                  class="inline-flex items-center px-1 pt-1 border-b border-transparent text-sm leading-5 focus:outline-none focus:border-white transition duration-150 ease-in-out"
                  >{{ $t("user.menu.patient.create") }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <main>
      <router-view :key="$route.fullPath" />
    </main>
  </div>
</template>

<script>
export default {
  name: "PatientPage",
};
</script>
